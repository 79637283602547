import { Contact } from "@/domain/contact/contact";
import { httpClient } from "./http-client";
const basePath = "/contact";

export const contactAPI = {
  getByIdentifier: async (identifier: string) => 
  {
    const url = basePath + "/getbyidentifier?identifier=" + identifier;
    const response = await httpClient.get(url);
    return response.data as Contact;
  },
  setStatus: async (identifier: string, status: string) => 
  {
    const url = basePath + "/set-status";
    return await httpClient.post(url, {
      identifier: identifier,
      contactStatus: status
    });
  },
  add: async (accountIdentifier: string, name: string, emailAddress: string, telephoneNumber: string) => 
  {
    const url = basePath + "/add";
    return await httpClient.post(url, {
      accountIdentifier: accountIdentifier,
      name: name,
      emailAddress: emailAddress,
      telephoneNumber: telephoneNumber
    });
  },
  getByAccountIdentifer: async (accountIdentifier: string) => 
  {
    const url = basePath + "/getbyaccountidentifier?accountIdentifier=" + accountIdentifier;
    const response = await httpClient.get(url);
    return response.data as Contact;
  },
};