<template>
<div class="content content-fixed bd-b">
  <div class="container pd-x-0 pd-lg-x-10 pd-xl-x-0\">
    <div class="d-sm-flex align-items-center justify-content-between">
    <div>
      <nav aria-label="breadcrumb">
        <ol class="breadcrumb breadcrumb-style1 mg-b-10">
          <li class="breadcrumb-item"><a href="/">Dashboard</a></li>
        </ol>
      </nav>
      <h4 class="mg-b-0 tx-spacing--1">Lead Finder</h4>
    </div>
    <div class="mg-t-20 mg-sm-t-0">
    </div>
    </div>
  </div>
</div>


        <div class="row row-xs">
          
    <div style="padding-top: 5px;">
      <h1>Lead Finder</h1>
      <div style="padding-top: 25px;">
        <p>show list of cemetires and sizes in the uk</p>
      </div>
    </div>
  </div>

  </template>
  