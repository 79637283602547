function logError(e: unknown) {
    //console.log(e);
    // TODO: log error to external system
  }

function unauthorisedRedirect(e: any) {
  if (e?.response?.request?.status == 403)
  {
    localStorage.removeItem('jbpw-auth-token')
    window.location.href = "/";
    return;
  }
}

function clientErrorResponse(e: any) {
  if (e?.response?.request == null)
  {
    return "Something went wrong. Please try again.";
  }
  return e.response.data.Data;
}
  
  export function useErrorLogger() {
    return {
      catchPromise: async <T>(callback: () => Promise<T>) => {
        try {
          const result: T = await callback();
          return {
            success: true,
            data: result,
          };
        } catch (e) {
          logError(e);
          unauthorisedRedirect(e);
          const errorResponse = clientErrorResponse(e);
          return {
            success: false,
            data: errorResponse,
          };
        }
      },
      catch: <T>(callback: () => T) => {
        try {
          const result: T = callback();
          return {
            success: true,
            data: result,
          };
        } catch (e) {
          logError(e);
          return {
            success: false,
            data: undefined,
          };
        }
      },
    };
  }  