import { Order } from "@/domain/order/order";
import { OrdersResult } from "@/domain/order/ordersResult";
import { httpClient } from "./http-client";
const basePath = "/order";

export const orderAPI = {
  getByIdentifier: async (identifier: string) => 
  {
    const url = basePath + "/getbyidentifier?identifier=" + identifier;
    const response = await httpClient.get(url);
    return response.data as Order;
  },
  search: async (accountIdentifier: string) => 
  {
    const url = basePath + "/search?accountIdentifier=" + accountIdentifier;
    const response = await httpClient.get(url);
    return response.data as OrdersResult;
  },
  setOrderStatus: async (identifier: string, status: string) => 
  {
    const url = basePath + "/set-status";
    return await httpClient.post(url, {
        identifier: identifier,
        orderStatus: status
    });
  },
  setPaymentComplete: async (identifier: string) => 
  {
    const url = basePath + "/set-payment-complete";
    return await httpClient.post(url, {
        identifier: identifier
    });
  },
  create: async (accountIdentifier: string) => 
  {
    const url = basePath + "/create";
    return await httpClient.post(url, {
        AccountIdentifier: accountIdentifier
    });
  },
  // add document?
  addSignedOrderContract: async (identifier: string, type: string) => 
  {
    const url = basePath + "/addSignedOrderContract";
    return await httpClient.post(url, {
        identifier: identifier,
        type: type
    });
  },
};