<template>
  <div></div>
    <div data-test-key="feedback-notification"
      v-if="this.feedbackText != ''" 
      :class="{ 
                  'success': this.feedbackLevel == 'success',
                  'alert-primary': this.feedbackLevel == 'notification',
                  'warning': this.feedbackLevel == 'warning',
                  'danger': this.feedbackLevel == 'error' }"
          class="alert ">
        {{ this.notificationText }}
    </div>
</template>

<script>
export default {
  props: ["feedbackText", "feedbackLevel"],
  components: {
  },
  data () {
    return {
      notificationText: this.feedbackText,
      notificationLevel: this.feedbackLevel
    }
  },
  methods: {

  },
  computed: {
    
  },
  watch: {
    feedbackText() {
      this.notificationText = this.feedbackText;
      this.notificationLevel = this.feedbackLevel;
    }
  }
}
</script>

<style scoped>
._divNotification {
    position: relative;
    margin:0 auto;
    margin-top: 10px;
    padding: 1px; 
    text-align: left;
    max-width: 500px; 
    border-left: 5px solid #59c9e8;   
    box-shadow: 1px 7px 10px -5px rgba(0,0,0,0.2);
}
._divNotification p {
    margin-left: 15px;
}

.success {
    border-left: 5px solid #42b983;   
}

.notification {
    border-left: 5px solid #59c9e8;   
}

.warning {
    border-left: 5px solid #eed202;   
}

.error {
    border-left: 5px solid #F84F31;   
}
</style>