import { ref, computed, ComputedRef } from "vue";

export enum Statuses {
  off = "off",
  pending = "pending",
  ready = "ready",
  error = "error",
}

export interface StatusFlags {
  off: ComputedRef<boolean>;
  pending: ComputedRef<boolean>;
  ready: ComputedRef<boolean>;
  error: ComputedRef<boolean>;
}

export function useStatus() {
  const statusValue = ref(Statuses.off);

  return {
    value: statusValue,

    flags: {
      off: computed(() => statusValue.value === Statuses.off),
      pending: computed(() => statusValue.value === Statuses.pending),
      ready: computed(() => statusValue.value === Statuses.ready),
      error: computed(() => statusValue.value === Statuses.error),
    } as StatusFlags,

    observe: async <T>(action: () => Promise<T>) => {
      try {
        statusValue.value = Statuses.pending;
        const result = await action();
        statusValue.value = Statuses.ready;
        return result;
      } catch (e) {
        statusValue.value = Statuses.error;
        return Promise.reject(e);
      }
    },
  };
}
