<template>
    <div v-if="pageLoading">
      <LoaderView></LoaderView>
    </div>
    <div v-else>
      <select class="formInput" v-model="state.pageForm.status" v-on:change="handleSetAccountStatus"> 
        <option v-bind:key="statusType" v-for="statusType in statusTypes" :value="statusType">{{ statusType }}</option>
      </select><br/>
      <ValidationMessage v-if="v$.pageForm.status.$error" :validationMessage ="v$.pageForm.status.$errors[0].$message" />
      <FeedbackNotification v-if="this.notification.text != ''" 
        :feedbackText="this.notification.text" 
        :feedbackLevel="this.notification.level"/>  
      </div>
  </template>
  
  <script>
  
  import ValidationMessage from "@/components/ValidationMessage.vue";
  import LoaderView from "@/components/LoaderView.vue";
  import FeedbackNotification from "@/components/FeedbackNotification.vue";
  import { accountAPI } from "@/api/account";
  import { useStatus } from "@/api/composables/status";
  import { useErrorLogger } from "@/api/composables/errorLogging";
  
  import { reactive, computed } from 'vue' 
  import useVuelidate from '@vuelidate/core'
  import { required, minLength } from '@vuelidate/validators'
  
  const pageStatus = useStatus();
  const log = useErrorLogger();
  
  export default {
    props: ["accountIdentifier", "accountStatus"],
    components : {
      FeedbackNotification, ValidationMessage, LoaderView
    }, 
    setup () {
      const state = reactive({
        pageForm: {
          status: "",
        }
      });
      const rules = computed(() => {
        return {
          pageForm: {
            status:  { },
          }
        }
      })
      const v$ = useVuelidate(rules, state)
      return { state, v$ }
    },
    mounted(){
      this.state.pageForm.status = this.accountStatus;
    },
    data() {
      return {
        pageLoading : false,
        notification : {
          text : "",
          level : "",
        },
        statusTypes : ["Active", "Dormant"],
      };
    },
    methods: {
      async handleSetAccountStatus() {
        // validate the form
        this.v$.$validate();
        if(!this.v$.$error)
        {
          this.pageLoading = true;
          const result = await log.catchPromise(() =>
           pageStatus.observe(async () => {
             const result = await accountAPI.setStatus(this.accountIdentifier, this.state.pageForm.status);
                // success       
                 this.notification = { text : "Status updated", level : "success" };  
                // notify the parent view  
                 this.$emit('formsubmitted', this.state.pageForm.status );
              })
          );
          this.pageLoading = false;
          if (!result.success) {
            this.state.pageForm.status = this.accountStatus;
            this.notification = { text : result.data, level : "error" };      
          }
        }
      }
    },
  };
  </script>