<template>
  <div>
    <div style="padding: 20px;">
      <h4 style="float: left;">Notes [{{ notes.resultCount  }}]</h4>
      <div style="float: right;">
        <button v-if="!this.showForm" v-on:click="this.showForm = true" class="btn btn-sm pd-x-15 btn-white">
        <i data-feather="mail" class="wd-10 mg-r-5"></i> Add note
      </button>
      </div>
    </div>
    <div style="padding-top: 20px;">
            <AddNoteForm v-if="this.showForm" 
              @formsubmitted="handleSearch"
              :noteIdentifier="this.noteIdentifier" 
              :noteType="this.noteType" />    
        <button v-if="this.showForm" v-on:click="this.showForm = false">cancel</button>    
      </div>
      <div style="padding-top: 25px;">
        <FeedbackNotification style="margin-bottom: 30px;" v-if="this.notification.text != ''" 
        :feedbackText="this.notification.text" 
        :feedbackLevel="this.notification.level"/> 
        <div v-if="isPageLoading">
          <LoaderView></LoaderView>
        </div>
          <div v-else-if="notes.resultCount > 0">
            <table class="table table-invoice bd-b">
              <thead style="border-bottom: 2px solid #ccc;">
                <tr>
                  <th class="tx-left"><b>Note</b></th>
                  <th class="tx-right"><b>Date Created</b></th>
                </tr>
              </thead>
              <tbody>
                <tr v-bind:key="note.Identifier" v-for="note in notes.results">
                  <td class="tx-left">{{ note.NoteValue }}</td>
                  <td class="tx-right">{{ new Date(note.DateCreated).toLocaleString() }}</td>
                </tr>
              </tbody>
            </table>
        </div>
        <div v-else>
          <FeedbackNotification style="margin-bottom: 30px;" 
            feedbackText="No notes found" 
            feedbackLevel="notification"/> 
      </div>
      </div>
    </div>
  </template>
  
  <script>
  import { notesAPI } from "@/api/notes";
  import AddNoteForm from "@/components/Forms/AddNoteForm.vue";
  import FeedbackNotification from "@/components/FeedbackNotification.vue";
  import LoaderView from "@/components/LoaderView.vue";
  import { useStatus } from "@/api/composables/status";
  import { useErrorLogger } from "@/api/composables/errorLogging";
  
  const pageStatus = useStatus();
  const log = useErrorLogger();
  
  export default {
    props: ["noteIdentifier", "noteType"],
    components: { FeedbackNotification, AddNoteForm, LoaderView },
    data() {
      return {
        showForm : false,
        notes : {
          results : [],
          resultCount: 0,
          pageSize: 0,
          pageNumber: 1
        },        
        isPageLoading : true,      
        notification : {
          text : "",
          level : "",
        },
      };
    },
   created(){
      this.handleSearch()
   },
    methods: {
      async handleSearch() {
        this.showForm = false;
        const result = await log.catchPromise(() =>
          pageStatus.observe(async () => {
            const result = await notesAPI.getNotes(this.noteIdentifier, this.noteType);
              this.notes.results = result.Results;
              this.notes.resultCount = result.ResultCount
              this.notes.pageSize = result.PageSize
              this.notes.pageNumber = result.PageNumber

              console.log(result)
          })
        );
        this.isPageLoading = false;
        if (!result.success) {
          this.notification = { text : result.data, level : "error" };          
        }
      }
    },
  };
  </script>