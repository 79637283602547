<template>
    <span class="_validationMessage" data-test-key="validation-message">{{ message }}</span>
</template>

<script>
export default {
  props: ["validationMessage"],
  components: {
  },
  data () {
    return {
      message: this.validationMessage,
    }
  },
  watch: {
    validationMessage() {
      this.message = this.validationMessage;
    }
  },
  // mounted() {
    
  // }
}
</script>

<style scoped>
._validationMessage {
    color: red;
    margin-top: 5px;
    text-align: left;
    font-size: 12px;
}
</style>