<template>
<div class="content content-fixed bd-b">
  <div class="container pd-x-0 pd-lg-x-10 pd-xl-x-0\">
    <div class="d-sm-flex align-items-center justify-content-between">
    <div>
      <nav aria-label="breadcrumb">
        <ol class="breadcrumb breadcrumb-style1 mg-b-10">
          <li class="breadcrumb-item"><a href="#">Website Contact Requests</a></li>
        </ol>
      </nav>
      <h4 class="mg-b-0 tx-spacing--1">Website Contact Requests</h4>
    </div>
    <div class="mg-t-20 mg-sm-t-0">
    </div>
    </div>
  </div>
</div>

<div class="content tx-13">
  <div class="container pd-x-0 pd-lg-x-10 pd-xl-x-0">
    <div class="row row-xs">
        <div v-if="isPageLoading">
          <LoaderView></LoaderView>
        </div>
        <div v-else-if="contactUsRequestResult.resultCount > 0">
          <p><b>Total Requests: </b> [{{ contactUsRequestResult.resultCount  }}]</p>
          <table class="table table-invoice bd-b">
              <thead style="border-bottom: 2px solid #ccc;">
                <tr>
                  <th class="tx-left"><b>Email Address</b></th>
                  <th class="tx-right"><b>Status</b></th>
                  <th class="tx-right"><b>Message</b></th>
                  <th class="tx-right"><b>Date Created</b></th>
                  <th class="tx-right"><b>Status</b></th>
                </tr>
              </thead>
              <tbody>
                <tr v-bind:key="contactUsRequest.Identifier" v-for="contactUsRequest in contactUsRequestResult.results">
                  <td class="tx-left">{{ contactUsRequest.EmailAddress }}</td>
                  <td class="tx-right">{{ contactUsRequest.Status }}</td>
                  <td class="tx-right">{{ contactUsRequest.ContactText }}</td>
                  <td class="tx-right">{{ new Date(contactUsRequest.DateCreated).toLocaleString() }}</td>
                  <td class="tx-right">
                    <select >
                      <option>new</option>
                      <option>picked up</option>
                      <option>resolved</option>
                    </select>
                  </td>
                </tr>
              </tbody>
            </table>
      </div>
      <div v-else>
        <FeedbackNotification style="margin-bottom: 30px;" 
          feedbackText="No contact requests found" 
          feedbackLevel="notification"/> 
      </div>
    </div>
  </div>
</div><!-- row -->

</template>

<script>
import { siteAPI } from "@/api/site";
import FeedbackNotification from "@/components/FeedbackNotification.vue";
import LoaderView from "@/components/LoaderView.vue";
import { useStatus } from "@/api/composables/status";
import { useErrorLogger } from "@/api/composables/errorLogging";

const pageStatus = useStatus();
const log = useErrorLogger();

export default{
  components: { FeedbackNotification, LoaderView },
  data() {
    return {
      contactUsRequestResult : {
        results : [],
        resultCount: 0,
        pageSize: 0,
        pageNumber: 1
      },
      isPageLoading : true,      
      notification : {
        text : "",
        level : "",
      },
    };
  },
 created(){
    this.handleGetContactRequests()
 },
  mounted() {
    document.title = 'Contact Requests - CliffeX.com'
  },
  methods: {
    async handleGetContactRequests() {
      const result = await log.catchPromise(() =>
        pageStatus.observe(async () => {
          const result = await siteAPI.getContactRequests();
          this.contactUsRequestResult.results = result.Results;
          this.contactUsRequestResult.resultCount = result.ResultCount
          this.contactUsRequestResult.pageSize = result.PageSize
          this.contactUsRequestResult.pageNumber = result.PageNumber
        })
      );
      this.isPageLoading = false;
      if (!result.success) {
        this.notification = { text : result.data, level : "error" };          
      }
    },
  },
};
</script>