//import { CompanyUser } from "@/domain/companyUser/companyUser";
import { httpClient } from "./http-client";
const basePath = "/authorisation";

export const authorisationAPI = {
  login: async (userName: string, password: string) => 
  {
    const url = basePath + "/login";
    return await httpClient.post(url, {
        userName: userName,
        password: password
      }, {
        headers: {
            'Content-Type': 'application/json',
        }
    });
  },
  logout: async () => 
  {
    const url = basePath + "/logout";
    return await httpClient.post(url);
  },
  get: async () => 
  {
    const url = basePath + "/get";
    const response = await httpClient.get(url);
    return response.data;
  }
}