<template>

<div class="content content-fixed bd-b">
  <div class="container pd-x-0 pd-lg-x-10 pd-xl-x-0\">
    <div class="d-sm-flex align-items-center justify-content-between">
    <div>
      <nav aria-label="breadcrumb">
        <ol class="breadcrumb breadcrumb-style1 mg-b-10">
          <li class="breadcrumb-item"><a href="/">Dashboard</a></li>
        </ol>
      </nav>
      <h4 class="mg-b-0 tx-spacing--1">Dashboard - CliffeX</h4>
    </div>
    <div class="mg-t-20 mg-sm-t-0">
    </div>
    </div>
  </div>
</div>


<div class="content tx-13">
  <div class="container pd-x-0 pd-lg-x-10 pd-xl-x-0">
    <div class="row row-xs">
      <label class="tx-sans tx-10 tx-medium tx-spacing-1 tx-color-03" style="position: left;">Statistics</label>
        <ul class="list-unstyled lh-7">
          <li class="d-flex justify-content-between" style="max-width:400px;">
            <span>Total active accounts</span><span>X</span>
          </li>
          <li class="d-flex justify-content-between" style="max-width:400px;">
            <span>Total orders completed</span><span>X</span>
          </li>
          <li class="d-flex justify-content-between" style="max-width:400px;">
            <span>Total orders in progress</span><span>X</span>
          </li>
          <li class="d-flex justify-content-between" style="max-width:400px;">
            <span>Total sales revenue</span><span>X</span>
          </li>
          <li class="d-flex justify-content-between" style="max-width:400px;">
            <span>Total sales profit</span><span>X</span>
          </li>
        </ul>
        <ul class="list-unstyled lh-7">
          <label class="tx-sans tx-10 tx-medium tx-spacing-1 tx-color-03" style="position: left;">Action required</label>
          <!-- <li class="d-flex justify-content-between" style="max-width:400px;">
            <span>Active accounts without order</span><span>X</span>
          </li> -->
          <li class="d-flex justify-content-between" style="max-width:400px;">
            <span>Open website contact requests</span><span>X</span>
          </li>
          <li class="d-flex justify-content-between" style="max-width:400px;">
            <span>Orders completed and awaiting payment</span><span>X</span>
          </li>
        </ul>
        <!-- <ul class="list-unstyled lh-7">
           <li class="d-flex justify-content-between" style="max-width:400px;">
            <span>Open leads status</span><span>X</span>
          </li> 
          <li class="d-flex justify-content-between" style="max-width:400px;">
            <span>started</span><span>X</span>
          </li>
          <li class="d-flex justify-content-between" style="max-width:400px;">
            <span>contract complete</span><span>X</span>
          </li>
          <li class="d-flex justify-content-between" style="max-width:400px;">
            <span>ManufacturingInProgress</span><span>X</span>
          </li>
          <li class="d-flex justify-content-between" style="max-width:400px;">
            <span>InTransit</span><span>X</span>
          </li>
          <li class="d-flex justify-content-between" style="max-width:400px;">
            <span>Delivered</span><span>X</span>
          </li>
          <li class="d-flex justify-content-between" style="max-width:400px;">
            <span>Complete</span><span>X</span>
          </li>
          <li class="d-flex justify-content-between" style="max-width:400px;">
            <span>Canceled</span><span>X</span>
          </li>
        </ul> -->
    </div>
  </div>
</div>

</template>

