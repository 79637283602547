<template>
    <h4>Add new account</h4>
    <div v-if="pageLoading">
      <LoaderView />
    </div>
    <div v-else>
      <div v-if="!isFormSubmitted">
        <p>
          <input class="formInput" v-model="state.registerForm.cemeteryName" placeholder="cemetery name" /><br>
          <ValidationMessage v-if="v$.registerForm.cemeteryName.$error" :validationMessage ="v$.registerForm.cemeteryName.$errors[0].$message"/>
        </p>
        <p>
          <input class="formInput" v-model="state.registerForm.name" placeholder="contact name" /><br>
          <ValidationMessage v-if="v$.registerForm.name.$error" :validationMessage ="v$.registerForm.name.$errors[0].$message"/>
        </p>
        <p>
          <input class="formInput" v-model="state.registerForm.emailAddress" placeholder="contact email address" /><br>
          <ValidationMessage v-if="v$.registerForm.emailAddress.$error" :validationMessage ="v$.registerForm.emailAddress.$errors[0].$message"/>
        </p>
        <p>
          <input class="formInput" v-model="state.registerForm.telephone" placeholder="contact telephone" /><br>
          <ValidationMessage v-if="v$.registerForm.telephone.$error" :validationMessage ="v$.registerForm.telephone.$errors[0].$message"/>
        </p>
        <p>
          <button class="btn btn-brand-02 btn-block" v-on:click="handleRegisterAccount">Register</button>
        </p>
      </div>
      <FeedbackNotification v-if="this.notification.text != ''" 
        :feedbackText="this.notification.text" 
        :feedbackLevel="this.notification.level"/>  
    </div>
  </template>
  
  <script>
  
  import ValidationMessage from "@/components/ValidationMessage.vue";
  import FeedbackNotification from "@/components/FeedbackNotification.vue";
  import LoaderView from "@/components/LoaderView.vue";
  import { accountAPI } from "@/api/account";
  import { useStatus } from "@/api/composables/status";
  import { useErrorLogger } from "@/api/composables/errorLogging";
  
  import { reactive, computed } from 'vue' 
  import useVuelidate from '@vuelidate/core'
  import { required, email, minLength } from '@vuelidate/validators'
  
  const pageStatus = useStatus();
  const log = useErrorLogger();
  
  export default {
    components : {
      FeedbackNotification, ValidationMessage, LoaderView
    }, 
    setup () {
      const state = reactive({
        registerForm: {
          cemeteryName: "",
          name: "",
          emailAddress: "",
          telephone: "",
        }
      });
      const rules = computed(() => {
        return {
          registerForm: {
            cemeteryName:  { required, minLength: minLength(3) },
            name: { required, minLength: minLength(3) },
            emailAddress: { required, email },
            telephone: { required },
          }
        }
      })
      const v$ = useVuelidate(rules, state)
      return { state, v$ }
    },
    data() {
      return {
        pageLoading : false,
        notification : {
          text : "",
          level : "",
        },
        isFormSubmitted: false
      };
    },
    methods: {
      async handleRegisterAccount() {
        // validate the form
        this.v$.$validate();
        if(!this.v$.$error)
        {
          this.pageLoading = true;
          const result = await log.catchPromise(() =>
           pageStatus.observe(async () => {
             const result = await accountAPI.register(
              this.state.registerForm.cemeteryName, 
              this.state.registerForm.name, 
              this.state.registerForm.emailAddress, 
              this.state.registerForm.telephone);
                // success       
                 this.isFormSubmitted = true;
                 this.notification = { text : "Account Registered.", level : "success" };  
                 // notify the parent view  
                 this.$emit('formsubmitted');
               })
           );
          this.pageLoading = false;
           if (!result.success) {
             this.notification = { text : result.data, level : "error" };      
           }
        }
      }
    },
  };
  </script>