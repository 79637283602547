  <template>

<div class="content content-fixed bd-b">
  <div class="container pd-x-0 pd-lg-x-10 pd-xl-x-0\">
    <div class="d-sm-flex align-items-center justify-content-between">
    <div>
      <nav aria-label="breadcrumb">
        <ol class="breadcrumb breadcrumb-style1 mg-b-10">
          <li class="breadcrumb-item"><a href="/">Leads</a></li>
        </ol>
      </nav>
      <h4 class="mg-b-0 tx-spacing--1">All Leads</h4>
    </div>
    <div class="mg-t-20 mg-sm-t-0">

    </div>
    </div>
  </div>
</div>

<div class="content tx-13">
  <div class="container pd-x-0 pd-lg-x-10 pd-xl-x-0">
    <div class="row row-xs">          
      <div>
        <AddLeadForm v-if="this.showAddLeadForm" @formsubmitted="handleGetCompanyUsers" />
        <button v-if="!this.showAddLeadForm" v-on:click="this.showAddLeadForm = true" class="btn btn-sm pd-x-15 btn-white btn-uppercase"><i data-feather="mail" class="wd-10 mg-r-5"></i> Add lead</button>
        <button v-if="this.showAddLeadForm" v-on:click="this.showAddLeadForm = false">Cancel</button>
      </div>
      <div>
        <FeedbackNotification style="margin-bottom: 30px;" v-if="this.notification.text != ''" 
        :feedbackText="this.notification.text" 
        :feedbackLevel="this.notification.level"/> 
          <div v-if="isPageLoading">
            <LoaderView></LoaderView>
          </div>
          <div v-else-if="leads.resultCount > 0">
            <p><b>Total Leads: </b> [{{ leads.resultCount  }}]</p>
            <table style="margin-left: auto; margin-right: auto; padding: 10px;">
              <tr>
                <td><b>Name</b></td>
                <td><b>Cemetery</b></td>
                <td><b>TelephoneNumber</b></td>
                <td><b>EmailAddress</b></td>
                <td><b>Status</b></td>
                <td><b>Created</b></td>
                <td></td>
              </tr>
              <tr v-bind:key="lead.Identifier" v-for="lead in leads.results">
                <td style="width:150px;">{{ lead.Name }}</td>
                <td style="width:150px;">{{ lead.CemeteryName }}</td>
                <td style="width:150px;">{{ lead.TelephoneNumber }}</td>
                <td style="width:150px;">{{ lead.EmailAddress }}</td>
                <td style="width:150px;">{{ lead.Status }}</td>
                <td style="width:200px;">{{ lead.DateCreated }}</td>
                <td style="width:150px;">
                  <button style="cursor: pointer;" v-on:click="leadClick(lead.Identifier)">
                    view 
                  </button>
                </td>
              </tr>
            </table>
        </div>
        <div v-else>
          <FeedbackNotification style="margin-bottom: 30px;" 
            feedbackText="No accounts found" 
            feedbackLevel="notification"/> 
        </div>
      </div>
    </div>
  </div>
</div>

  </template>

  <script>
  import { leadAPI } from "@/api/lead";
  import AddLeadForm from "@/components/Forms/AddLeadForm.vue";
  import FeedbackNotification from "@/components/FeedbackNotification.vue";
  import LoaderView from "@/components/LoaderView.vue";
  import { useStatus } from "@/api/composables/status";
  import { useErrorLogger } from "@/api/composables/errorLogging";
  
  const pageStatus = useStatus();
  const log = useErrorLogger();
  
  export default{
    components: { FeedbackNotification, AddLeadForm, LoaderView },
    data() {
      return {
        showAddLeadForm : false,
        leads : {
          results : [],
          resultCount: 0,
          pageSize: 0,
          pageNumber: 1
        },
        isPageLoading : true,      
        notification : {
          text : "",
          level : "",
        },
      };
    },
   created(){
      this.handleSearchleads()
   },
    mounted() {
      document.title = 'Leads - CliffeX.com'
    },
    methods: {
      async handleSearchleads() {
        this.isPageLoading = true;
        const result = await log.catchPromise(() =>
          pageStatus.observe(async () => {
            const result = await leadAPI.search('');
            this.leads.results = result.Results;
            this.leads.resultCount = result.ResultCount
            this.leads.pageSize = result.PageSize
            this.leads.pageNumber = result.PageNumber
          })
        );
        this.isPageLoading = false;
        if (!result.success) {
          this.notification = { text : result.data, level : "error" };          
        }
      },
      async leadClick(leadIdentifier) {
        this.$router.push('/leads/' + leadIdentifier);
      },
    },
  };
  </script>