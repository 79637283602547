import { Account } from "@/domain/account/account";
import { AccountsResult } from "@/domain/account/accountsResult";
import { httpClient } from "./http-client";
const basePath = "/account";

export const accountAPI = {
  getByIdentifier: async (identifier: string) => 
  {
    const url = basePath + "/getbyidentifier?identifier=" + identifier;
    const response = await httpClient.get(url);
    return response.data as Account;
  },
  search: async (companyIdentifier: string) => 
  {
    const url = basePath + "/search?SortOrder=CreatedDateAscending";
    const response = await httpClient.get(url);
    return response.data as AccountsResult;
  },
  setStatus: async (identifier: string, status: string) => 
  {
    const url = basePath + "/set-status";
    return await httpClient.post(url, {
        identifier: identifier,
        accountStatus: status
    });
  },
  register: async (cemeteryName: string, name: string, emailAddress: string, telephoneNumber: string) => 
  {
    const url = basePath + "/register";
    return await httpClient.post(url, {
      cemeteryName : cemeteryName,
      name: name,
      emailAddress: emailAddress,
      telephoneNumber: telephoneNumber
    });
  },
};