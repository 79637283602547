<template>

<div class="content content-fixed bd-b">
  <div class="container pd-x-0 pd-lg-x-10 pd-xl-x-0\">
    <div class="d-sm-flex align-items-center justify-content-between">
    <div>
      <nav aria-label="breadcrumb">
      <ol class="breadcrumb breadcrumb-style1 mg-b-10">
        <li class="breadcrumb-item"><a href="/accounts/">Accounts</a></li>
        <li class="breadcrumb-item" aria-current="page">
          <a v-on:click="handleAccountClick">Account</a>
        </li>
        <li class="breadcrumb-item active" aria-current="page">Order</li>
      </ol>
    </nav>
      <h4 class="mg-b-0 tx-spacing--1">Order [{{ orderIdentifier }}]</h4>
    </div>
    <div class="mg-t-20 mg-sm-t-0">
      <div class="col-sm-6 tx-right d-none d-md-block" style="min-width: 120px;">
        <label class="tx-sans tx-uppercase tx-10 tx-medium tx-spacing-1 tx-color-03">Order Status</label>
        <h1 class="tx-normal tx-color-04 mg-b-10 tx-spacing--2">
          {{ this.order?.Status }}
        </h1>
      </div>
    </div>
    </div>
  </div>
</div>

<div class="content tx-13">
  <div class="container pd-x-0 pd-lg-x-10 pd-xl-x-0">
    <div class="row row-xs">
      <FeedbackNotification style="margin-bottom: 30px;" v-if="this.notification.text != ''" 
      :feedbackText="this.notification.text" 
      :feedbackLevel="this.notification.level"/> 
      <div v-if="isPageLoading">
        <LoaderView></LoaderView>
      </div>
      <div v-else-if="order != null">  
        <div>
          <ul class="list-unstyled lh-7">
              <li class="d-flex justify-content-between" style="max-width:400px;">
                <span>Identifier</span><span>{{ orderIdentifier }}</span>
              </li>
              <li class="d-flex justify-content-between" style="max-width:400px;">
                <span>Status</span><span> 
                  <SetOrderStatusForm  
                    v-if="this.order != null"
                    @formsubmitted="this.handleStatusUpdated"
                    :orderIdentifier="this.orderIdentifier" 
                    :orderStatus="this.order.Status" 
                  />
                </span>
              </li>
              <li class="d-flex justify-content-between" style="max-width:400px;">
                <span>Sale Price</span><span>£xxxx</span>
              </li>
              <li class="d-flex justify-content-between" style="max-width:400px;">
                <span>Cost Price</span><span>£xxxx</span>
              </li>
              <li class="d-flex justify-content-between" style="max-width:400px;">
                <span>Number of products</span><span>£xxxx</span>
              </li>
              <li class="d-flex justify-content-between" style="max-width:400px;">
                <span>Delivery Price</span><span>£xxxx</span>
              </li>
              <li class="d-flex justify-content-between" style="max-width:400px;">
                <span>Date Updated</span><span>{{ order.DateUpdated != null ? new Date(order.DateUpdated).toLocaleString() : 'n/a' }}</span>
              </li>
              <li class="d-flex justify-content-between" style="max-width:400px;">
                <span>Date Created</span><span>{{ new Date(order.DateCreated).toLocaleString() }}</span>
              </li>
            </ul>
          </div>
        <div style="padding-top: 25px;">
          <button v-if="!this.showPaymentCompleteForm" v-on:click="this.showPaymentCompleteForm = true">Add order expense (delivery)</button>
          <PaymentCompleteForm v-if="this.showPaymentCompleteForm" @formsubmitted="handleGetCompanyUsers"  />
          <button v-if="this.showPaymentCompleteForm" v-on:click="this.showPaymentCompleteForm = false">cancel</button>    
        </div>
        <div style="padding-top: 25px;">
          <button v-if="!this.showPaymentCompleteForm" v-on:click="this.showPaymentCompleteForm = true" class="btn btn-sm pd-x-15 btn-white" >Set payment complete</button>
          <PaymentCompleteForm v-if="this.showPaymentCompleteForm" @formsubmitted="handleGetCompanyUsers"  />
          <button v-if="this.showPaymentCompleteForm" v-on:click="this.showPaymentCompleteForm = false">cancel</button>    
        </div>
        <div style="padding-top: 25px;">
          <button v-if="!this.showAddSignedContractToOrderForm" v-on:click="this.showAddSignedContractToOrderForm = true" class="btn btn-sm pd-x-15 btn-white" >Add document</button>
          <AddSignedContractToOrderForm v-if="this.showAddSignedContractToOrderForm" @formsubmitted="handleGetCompanyUsers"  />
          <button v-if="this.showAddSignedContractToOrderForm" v-on:click="this.showAddSignedContractToOrderForm = false">cancel</button>    
          <p>Show documentes/ name / type </p>
        </div>
        <div style="padding-top: 25px;">
          <NoteContainer 
                  :noteIdentifier="this.orderIdentifier" 
                  noteType="Order"  
                />  
        </div>
        <div style="padding-top: 25px;">
          <p>View table of full audit trail of the order</p>
        </div>
      </div>
    </div>
  </div> 
</div>

</template>


<script>

import LoaderView from "@/components/LoaderView.vue";
import FeedbackNotification from "@/components/FeedbackNotification.vue";
import NoteContainer from "@/components/NoteContainer.vue";
import PaymentCompleteForm from "@/components/Forms/PaymentCompleteForm.vue";
import SetOrderStatusForm from "@/components/Forms/SetOrderStatusForm.vue";
import AddSignedContractToOrderForm from "@/components/Forms/AddSignedContractToOrderForm.vue";
import { orderAPI } from "@/api/order";
import router from "@/router";

import { useStatus } from "@/api/composables/status";
import { useErrorLogger } from "@/api/composables/errorLogging";
const pageStatus = useStatus();
const log = useErrorLogger();

export default {
  components :{
    FeedbackNotification, NoteContainer, PaymentCompleteForm, SetOrderStatusForm, AddSignedContractToOrderForm, LoaderView
  },  
  data() {
    return {
      orderIdentifier : this.$route.params.orderIdentifier,
      showPaymentCompleteForm : false,
      order : null,
      isPageLoading : true,
      showAddSignedContractToOrderForm : false,
      notification : {
        text : "",
        level : "",
      },
    }
  },
  created(){
      this.handleGetOrder()
  },
  mounted() {
    document.title = 'Order - CliffeX.com'
  },
  methods: {
    handleStatusUpdated: function(e)
    {
      this.order.Status = e;
    },
    handleAccountClick: function(e)
    {
      router.push({ name: 'AccountView', params: { accountIdentifier: this.order?.AccountIdentifier } });
    },
    async handleGetOrder() {
      this.isPageLoading = true;
      const result = await log.catchPromise(() =>
        pageStatus.observe(async () => {
          const result = await orderAPI.getByIdentifier(this.orderIdentifier);
          this.order = result;
        })
      );
      this.isPageLoading = false;
      if (!result.success) {
        this.notification = { text : result.data, level : "error" };          
      }
    },
  },
};
</script>