<template>
    <img data-test-key="image-loader" class="_imgLoader" src="@/assets/images/loading.gif"/>
</template>

<style scoped>
._imgLoader {
    margin: auto; 
    z-index: 1;
    width: 120px;
    height: 120px;
}
</style>