<template>

<div class="content content-fixed bd-b">
  <div class="container pd-x-0 pd-lg-x-10 pd-xl-x-0\">
    <div class="d-sm-flex align-items-center justify-content-between">
    <div>
      <nav aria-label="breadcrumb">
        <ol class="breadcrumb breadcrumb-style1 mg-b-10">
          <li class="breadcrumb-item"><a href="/">Leads</a></li>
          <li class="breadcrumb-item active" aria-current="page">Lead</li>
        </ol>
      </nav>
      <h4 class="mg-b-0 tx-spacing--1">Lead [{{ lead?.Name }}]</h4>
    </div>
    <div class="col-sm-6 tx-right d-none d-md-block" style="min-width: 120px;">
      <label class="tx-sans tx-uppercase tx-10 tx-medium tx-spacing-1 tx-color-03">Lead Status</label>
        <h1 class="tx-normal tx-color-04 mg-b-10 tx-spacing--2">
          {{ this.lead?.Status }}
        </h1>
    </div>
    </div>
  </div>
</div>

<div class="content tx-13">
  <div class="container pd-x-0 pd-lg-x-10 pd-xl-x-0">
    <div class="row row-xs">
    <div style="padding-top: 5px;">
      <FeedbackNotification style="margin-bottom: 30px;" v-if="this.notification.text != ''" 
        :feedbackText="this.notification.text" 
        :feedbackLevel="this.notification.level"/> 
      <div v-if="isPageLoading">
        <LoaderView></LoaderView>
      </div>
     <div v-else-if="lead != null">  
        <div >
          <ul class="list-unstyled lh-7">
            <li class="d-flex justify-content-between" style="max-width:400px;">
              <span>Identifier</span><span>{{ leadIdentifier }}</span>
            </li>
            <li class="d-flex justify-content-between" style="max-width:400px;">
              <span>Status</span>
              <span>
                <SetLeadStatusForm 
                  @formsubmitted="this.handleStatusUpdated"
                  :leadIdentifier="this.leadIdentifier" 
                  :leadStatus="this.lead.Status" 
                />
              </span>
            </li>
            <li class="d-flex justify-content-between" style="max-width:400px;">
              <span>Name</span><span>{{ lead.Name }}</span>
            </li>
            <li class="d-flex justify-content-between" style="max-width:400px;">
              <span>Email Address</span><span>{{ lead.EmailAddress }}</span>
            </li>
            <li class="d-flex justify-content-between" style="max-width:400px;">
              <span>Telephone Number</span><span>{{ lead.TelephoneNumber }}</span>
            </li>
            <li class="d-flex justify-content-between" style="max-width:400px;">
              <span>Date Updated</span><span>{{ lead.DateUpdated != null ? new Date(lead.DateUpdated).toLocaleString() : 'n/a' }}</span>
            </li>
            <li class="d-flex justify-content-between" style="max-width:400px;">
              <span>Date Created</span><span>{{ new Date(lead.DateCreated).toLocaleString() }}</span>
            </li>
          </ul>
        </div>
        <div style="padding-top: 25px;">
          
        </div>
        <div style="padding-top: 25px;">
          <NoteContainer 
              :noteIdentifier="this.leadIdentifier" 
              noteType="Lead"  
            />      
        </div>
      </div>
    </div>  
  </div>  
</div>  
    </div>  
</template>

<script>
import LoaderView from "@/components/LoaderView.vue";
import FeedbackNotification from "@/components/FeedbackNotification.vue";
import NoteContainer from "@/components/NoteContainer.vue";
import SetLeadStatusForm from "@/components/Forms/SetLeadStatusForm.vue";
import { leadAPI } from "@/api/lead";

import { useStatus } from "@/api/composables/status";
import { useErrorLogger } from "@/api/composables/errorLogging";
const pageStatus = useStatus();
const log = useErrorLogger();

export default {
  components :{
    FeedbackNotification, NoteContainer, SetLeadStatusForm, LoaderView
  },  
  data() {
      return {
        leadIdentifier : this.$route.params.leadIdentifier,
        isPageLoading : true,     
        lead : null, 
        notification : {
          text : "",
          level : "",
        },
      }
    },
    created(){
        this.handleGetLead()
    },
    mounted() {
      document.title = 'Lead - CliffeX.com'
    },
    
    methods: {
      handleStatusUpdated: function(e)
      {
        this.lead.Status = e;
      },
      async handleGetLead() {
        this.isPageLoading = true;
        const result = await log.catchPromise(() =>
          pageStatus.observe(async () => {
            const result = await leadAPI.getByIdentifier(this.leadIdentifier);
            this.lead = result;
          })
        );
        this.isPageLoading = false;
        if (!result.success) {
          this.notification = { text : result.data, level : "error" };          
        }
      },
  },
};
</script>