import { NotesResult } from "@/domain/notes/notesResult";
import { httpClient } from "./http-client";
const basePath = "/note";

export const notesAPI = {
  getNotes: async (identifier: string, type: string) => 
  {
    const url = basePath + "/getbynoteidentifier?noteIdentifier=" + identifier + "&noteType=" + type;
    const response = await httpClient.get(url);
    return response.data as NotesResult;
  },
  add: async (identifier: string, noteType: string, note: string) => 
  {
    const url = basePath + "/add";
    return await httpClient.post(url, {
        identifier: identifier,
        note: note,
        type: noteType
    });
  },
};