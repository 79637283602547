<template>
<div class="content content-fixed bd-b">
  <div class="container pd-x-0 pd-lg-x-10 pd-xl-x-0\">
    <div class="d-sm-flex align-items-center justify-content-between">
    <div>
      <nav aria-label="breadcrumb">
        <ol class="breadcrumb breadcrumb-style1 mg-b-10">
          <li class="breadcrumb-item active"><a href="/">Orders</a></li>
        </ol>
      </nav>
      <h4 class="mg-b-0 tx-spacing--1">Order overview</h4>
    </div>
    <div class="mg-t-20 mg-sm-t-0">
    </div>
    </div>
  </div>
</div>


<div class="content tx-13">
  <div class="container pd-x-0 pd-lg-x-10 pd-xl-x-0">
    <div class="row row-xs">
          <div>
      <FeedbackNotification style="margin-bottom: 30px;" v-if="this.notification.text != ''" 
      :feedbackText="this.notification.text" 
      :feedbackLevel="this.notification.level"/> 
        <div v-if="isOrdersLoading">
          <LoaderView></LoaderView>
        </div>
        <div v-else-if="orderResult.resultCount > 0">
          <p><b>Total Orders: </b> [{{ orderResult.resultCount  }}] -> show by status table</p>

          <table class="table table-invoice bd-b">
              <thead style="border-bottom: 2px solid #ccc;">
                <tr>
                  <th class="tx-left"><b>Account</b></th>
                  <th class="tx-right"><b>Status</b></th>
                  <th class="tx-right"><b>Date Created</b></th>
                  <th class="tx-right"><b></b></th>
                </tr>
              </thead>
              <tbody>
                <tr v-bind:key="order.Identifier" v-for="order in orderResult.results">
                  <td class="tx-left"><a href="#" v-on:click="accountClick(order.AccountIdentifier)"> {{ order.AccountIdentifier }}</a></td>
                  <td class="tx-right">{{ order.Status }}</td>
                  <td class="tx-right">{{ new Date(order.DateCreated).toLocaleDateString() }}</td>
                  <td class="tx-right">
                    <a href="#" v-on:click="orderClick(order.Identifier)">view order</a>
                  </td>
                </tr>
              </tbody>
            </table>
      </div>
      <div v-else>
        <FeedbackNotification style="margin-bottom: 30px;" 
          feedbackText="No orders found" 
          feedbackLevel="notification"/> 
      </div>
    </div>
  </div>
</div>
</div>

</template>

<script>
import { orderAPI } from "@/api/order";
import FeedbackNotification from "@/components/FeedbackNotification.vue";
import LoaderView from "@/components/LoaderView.vue";
import { useStatus } from "@/api/composables/status";
import { useErrorLogger } from "@/api/composables/errorLogging";

const pageStatus = useStatus();
const log = useErrorLogger();

export default{
  components: { FeedbackNotification, LoaderView },
  data() {
    return {
      notification : {
        text : "",
        level : "",
      },
      orderResult : {
        results : [],
        resultCount: 0,
        pageSize: 0,
        pageNumber: 1
      },
      isOrdersLoading : true,      
    };
  },
 created(){
    this.handleGetOrders()
 },
  mounted() {
    document.title = 'Contact Requests - CliffeX.com'
  },
  methods: {
    async handleGetOrders() {
      this.isOrdersLoading = true;
      const result = await log.catchPromise(() =>
        pageStatus.observe(async () => {
          const result = await orderAPI.search('');
          this.orderResult.results = result.Results;
          this.orderResult.resultCount = result.ResultCount
          this.orderResult.pageSize = result.PageSize
          this.orderResult.pageNumber = result.PageNumber
        })
      );
      this.isOrdersLoading = false;
      if (!result.success) {
        this.notification = { text : result.data, level : "error" };          
      }
    },
    async accountClick(accountIdentifier) {
      this.$router.push('/accounts/' + accountIdentifier);
    },
    async orderClick(orderIdentifier) {
      this.$router.push('/orders/' + orderIdentifier);
    },
  },
};
</script>