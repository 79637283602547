import { createApp } from "vue";
import App from "./App.vue";
import "./registerServiceWorker";
import router from "./router";

//import "@/assets/lib/ionicons/css/ionicons.min.css";


import "@/assets/css/dashforge.css";
import "@/assets/css/dashforge.dashboard.css";


createApp(App).use(router).mount("#app");
