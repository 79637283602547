<template>
  <div data-test-key="not-found-page">
    <h1>Not Found</h1>
    <a href="/">dashboard</a>
  </div>
</template>

<script>
export default{
  data() {
    return {
    };
  },
  mounted() {
    document.title = '404 Not Found - admin.cliffex.co.uk'
  }
};
</script>