<template>
<div v-if="accessToken == null" style="padding: 30px;">
  <LoginForm />
</div>
<div style="padding-top: 30px;" v-else>
<!DOCTYPE html>
<html lang="en">
  <head>

    <!-- Required meta tags -->
    <meta charset="utf-8">
    <meta name="viewport" content="width=device-width, initial-scale=1, shrink-to-fit=no">

    <!-- Favicon -->
    <link rel="shortcut icon" type="image/x-icon" href="@/assets/img/favicon.png">

    <title>CliffeX</title>

    <!-- <link href="@/assets/lib/@fortawesome/fontawesome-free/css/all.min.css" rel="stylesheet"> -->
  </head>
  <body class="page-profile">
    <NavigationMenu @formsubmitted="handleLoginSubmit" />

 
          <router-view v-cloak/>


    <footer class="footer">
      <div>
        <span>&copy; 2023 CliffeX v1.0.0. </span>
      </div>
      <div>
        <nav class="nav">
        </nav>
      </div>
    </footer>
  </body>
</html>
</div>
</template>

<script>

import LoginForm from "@/components/Forms/LoginForm.vue";
import NavigationMenu from "@/components/NavigationMenu.vue";
import { authorisationAPI } from "@/api/authorisation";
import { useStatus } from "@/api/composables/status";
import { useErrorLogger } from "@/api/composables/errorLogging";
import { loadScript } from "vue-plugin-load-script";
import store from "./store/vuex";

const pageStatus = useStatus();
const log = useErrorLogger();

export default {
  name: "App",
  components : {
    NavigationMenu, LoginForm
  },
  data() {
    return {
      accessToken : localStorage.getItem('cliffex-auth-token'),
    };
  },
  async created() {
    if (localStorage.getItem('cliffex-auth-token') != null)
    {
      const result = await log.catchPromise(() =>
      pageStatus.observe(async () => {
          const result = await authorisationAPI.get();
          store.dispatch('cliffexUserStore', result);
        })
      );
      if (!result.success) {
          store.dispatch('cliffexUserStore', null);
          localStorage.removeItem('cliffex-auth-token');
      }  
    }
  },
}

loadScript("/assets/lib/jquery/jquery.min.js")
    .then(() => {
      loadScript("/assets/lib/feather-icons/feather.min.js")
      .then(() => {
        console.log("success")
        loadScript("/assets/lib/bootstrap/js/bootstrap.bundle.min.js");
        loadScript("/assets/lib/perfect-scrollbar/perfect-scrollbar.min.js");	
        loadScript("/assets/js/dashforge.js");
      });
	}).catch(() => {
      console.log("failed")
});

</script>

<style lang="scss">
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

//@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;700&display=swap");
body {
  padding: 0;
  font-family: "Roboto", sans-serif;
}
* {
  margin: 0;
  box-sizing: border-box;
}
a {
  font-family: inherit;
  text-decoration: none;
}
</style>
