<template>
    <h4>Add new order</h4>
    <div v-if="pageLoading">
      <LoaderView />
    </div>
    <div v-else>
      <div v-if="!isFormSubmitted">
        <select class="formInput" v-model="state.pageForm.status" v-on:change="handleSetAccountStatus"> 
          <option value="" disabled>Select a product</option>
          <option v-bind:key="statusType" v-for="statusType in statusTypes" :value="statusType">{{ statusType }}</option>
        </select><br/>
        <ValidationMessage v-if="v$.pageForm.status.$error" :validationMessage ="v$.pageForm.status.$errors[0].$message" />
        <p>
          <input class="formInput" v-model="state.pageForm.accountName" placeholder="quantity" /><br>
          <ValidationMessage v-if="v$.pageForm.accountName.$error" :validationMessage ="v$.pageForm.accountName.$errors[0].$message"/>
        </p>        
        
        <br/>
        <br/>
        <p>
          <input class="formInput" v-model="state.pageForm.accountName" placeholder="£ sale price" /><br>
          <ValidationMessage v-if="v$.pageForm.accountName.$error" :validationMessage ="v$.pageForm.accountName.$errors[0].$message"/>
        </p>
        <p>
          <input class="formInput" v-model="state.pageForm.accountName" placeholder="£ manufacturer price" /><br>
          <ValidationMessage v-if="v$.pageForm.accountName.$error" :validationMessage ="v$.pageForm.accountName.$errors[0].$message"/>
        </p>
        <p>
          <input class="formInput" v-model="state.pageForm.accountName" placeholder="£ delivery cost" /><br>
          <ValidationMessage v-if="v$.pageForm.accountName.$error" :validationMessage ="v$.pageForm.accountName.$errors[0].$message"/>
        </p>
        <p>
          <button class="btn btn-brand-02 btn-block" v-on:click="handleAddOrder">Create Order</button>
        </p>
      </div>
      <FeedbackNotification v-if="this.notification.text != ''" 
        :feedbackText="this.notification.text" 
        :feedbackLevel="this.notification.level"/>  
    </div>
  </template>
  
  <script>
  
  import ValidationMessage from "@/components/ValidationMessage.vue";
  import FeedbackNotification from "@/components/FeedbackNotification.vue";
  import LoaderView from "@/components/LoaderView.vue";
  import { orderAPI } from "@/api/order";
  import { useStatus } from "@/api/composables/status";
  import { useErrorLogger } from "@/api/composables/errorLogging";
  
  import { reactive, computed } from 'vue' 
  import useVuelidate from '@vuelidate/core'
  import { required, email, minLength } from '@vuelidate/validators'
  
  const pageStatus = useStatus();
  const log = useErrorLogger();
  
  export default {
    props: ["accountIdentifier"],
    components : {
      FeedbackNotification, ValidationMessage, LoaderView
    }, 
    setup () {
      const state = reactive({
        pageForm: {
          accountName: "",
        }
      });
      const rules = computed(() => {
        return {
          pageForm: {
            accountName:  { required, minLength: minLength(3) },
            status:  { required, minLength: minLength(3) },
          }
        }
      })
      const v$ = useVuelidate(rules, state)
      return { state, v$ }
    },
    data() {
      return {
        pageLoading : false,
        notification : {
          text : "",
          level : "",
        },
        isFormSubmitted: false,
        statusTypes : ["Single: Top section 6ft", "Single: Middle section 6ft", "Single: rebar", "Package: 6 Piece starter"],
      };
    },
  mounted(){
    this.state.pageForm.status = "";
  },
    methods: {
      async handleAddOrder() {
        // validate the form
        this.v$.$validate();
        if(!this.v$.$error)
        {
          this.pageLoading = true;
          const result = await log.catchPromise(() =>
           pageStatus.observe(async () => {
             const result = await orderAPI.create(this.accountIdentifier);
                // success       
                 this.isFormSubmitted = true;
                 this.notification = { text : "Order created", level : "success" };  
                // notify the parent view  
                 this.$emit('formsubmitted');
              })
          );
          this.pageLoading = false;
          if (!result.success) {
            this.notification = { text : result.data, level : "error" };      
          }
        }
      }
    },
  };
  </script>