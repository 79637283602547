<template>
<div class="content content-fixed bd-b">
  <div class="container pd-x-0 pd-lg-x-10 pd-xl-x-0\">
    <div class="d-sm-flex align-items-center justify-content-between">
    <div>
      <nav aria-label="breadcrumb">
      <ol class="breadcrumb breadcrumb-style1 mg-b-10">
        <li class="breadcrumb-item"><a href="/accounts/">Accounts</a></li>
        <li class="breadcrumb-item active" aria-current="page">Account</li>
      </ol>
    </nav>
      <h4 class="mg-b-0 tx-spacing--1">Account [{{ account?.CemeteryName }}]</h4>
    </div>
    <div class="mg-t-20 mg-sm-t-0">
      <div class="col-sm-6 tx-right d-none d-md-block" style="min-width: 120px;">
        <label class="tx-sans tx-uppercase tx-10 tx-medium tx-spacing-1 tx-color-03"> Account Status</label>
        <h1 class="tx-normal tx-color-04 mg-b-10 tx-spacing--2">
          {{ this.account?.Status }}
        </h1>
      </div>
    </div>
    </div>
  </div>
</div>

<div class="content tx-13">
  <div class="container pd-x-0 pd-lg-x-10 pd-xl-x-0">
    <div class="row row-xs">
    <div style="padding-top: 5px;">
      <FeedbackNotification style="margin-bottom: 30px;" v-if="this.notification.text != ''" 
        :feedbackText="this.notification.text" 
        :feedbackLevel="this.notification.level"/> 
      <div v-if="isPageLoading">
        <LoaderView></LoaderView>
      </div>
        <div v-else-if="account != null">  
          <div>
            <ul class="list-unstyled lh-7">
              <li class="d-flex justify-content-between" style="max-width:400px;">
                <span>Name</span><span>{{ account.CemeteryName }}</span>
              </li>
              <li class="d-flex justify-content-between" style="max-width:400px;">
                <span>Status</span>
                <span>
                  <SetAccountStatusForm 
                    v-if="this.account != null"
                    @formsubmitted="this.handleAccountStatusUpdated"
                    :accountIdentifier="this.accountIdentifier" 
                    :accountStatus="this.account.Status" />
                </span>
              </li>
              <li class="d-flex justify-content-between" style="max-width:400px;">
                <span>Date Updated</span><span>{{ account.DateUpdated != null ? new Date(account.DateUpdated).toLocaleString() : 'n/a' }}</span>
              </li>
              <li class="d-flex justify-content-between" style="max-width:400px;">
                <span>Date Created</span><span>{{ new Date(account.DateCreated).toLocaleString() }}</span>
              </li>
              <li class="d-flex justify-content-between" style="max-width:400px;">
                <span>Total revenue</span><span>X</span>
              </li>
              <li class="d-flex justify-content-between" style="max-width:400px;">
                <span>Total profit</span><span>X</span>
              </li>
            </ul>
          </div>
          <div style="padding-top: 25px;">
            <AccountOrdersContainer
              :accountIdentifier="this.accountIdentifier" 
            />
          </div>
          <div style="padding-top: 25px;">
            <AccountContactsContainer
              :accountIdentifier="this.accountIdentifier" 
            />
          </div>
          <div style="padding-top: 25px;">
            <NoteContainer 
                :noteIdentifier="this.accountIdentifier" 
                noteType="Account"  
              />      
          </div>
        </div>
      </div>
    </div>
  </div>        
</div>

</template>

<script>
import LoaderView from "@/components/LoaderView.vue";
import FeedbackNotification from "@/components/FeedbackNotification.vue";
import AccountOrdersContainer from "@/components/AccountOrdersContainer.vue";
import NoteContainer from "@/components/NoteContainer.vue";
import SetAccountStatusForm from "@/components/Forms/SetAccountStatusForm.vue";
import AccountContactsContainer from "@/components/AccountContactsContainer.vue";
import { accountAPI } from "@/api/account";

import { useStatus } from "@/api/composables/status";
import { useErrorLogger } from "@/api/composables/errorLogging";
const pageStatus = useStatus();
const log = useErrorLogger();

export default {
  components :{
    FeedbackNotification, AccountOrdersContainer, NoteContainer, SetAccountStatusForm, AccountContactsContainer, LoaderView
  },  
  data() {
      return {
        accountIdentifier : this.$route.params.accountIdentifier,
        isPageLoading : true,     
        account : null, 
        notification : {
          text : "",
          level : "",
        },
      }
    },
    created(){
        this.handleGetAccount()
    },
    mounted() {
      document.title = 'Account - CliffeX.com'
    },
    
    methods: {
      handleAccountStatusUpdated: function(e)
      {
        this.account.Status = e;
      },
      async handleGetAccount() {
        this.isPageLoading = true;
        const result = await log.catchPromise(() =>
          pageStatus.observe(async () => {
            const result = await accountAPI.getByIdentifier(this.accountIdentifier);
            this.account = result;
          })
        );
        this.isPageLoading = false;
        if (!result.success) {
          this.notification = { text : result.data, level : "error" };          
        }
      },
  },
};
</script>