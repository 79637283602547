<template>
<div class="content content-fixed bd-b">
  <div class="container pd-x-0 pd-lg-x-10 pd-xl-x-0\">
    <div class="d-sm-flex align-items-center justify-content-between">
    <div>
      <nav aria-label="breadcrumb">
        <ol class="breadcrumb breadcrumb-style1 mg-b-10">
          <li class="breadcrumb-item"><a href="/">Accounts</a></li>
        </ol>
      </nav>
      <h4 class="mg-b-0 tx-spacing--1">All Accounts</h4>
    </div>
    <div class="mg-t-20 mg-sm-t-0">
      <button v-if="!this.showAddAccountForm" v-on:click="this.showAddAccountForm = true" class="btn btn-sm pd-x-15 btn-white">
        <i data-feather="mail" class="wd-10 mg-r-5"></i> Add account
      </button>
    </div>
    </div>
  </div>
</div>

<div class="content tx-13">
  <div class="container pd-x-0 pd-lg-x-10 pd-xl-x-0">
    <div class="row row-xs">
    <div>
      <RegisterAccountForm v-if="this.showAddAccountForm" @formsubmitted="handleSearchAccounts" />
      <button v-if="this.showAddAccountForm" v-on:click="this.showAddAccountForm = false">cancel</button>
    </div>
    <div>
      <FeedbackNotification style="margin-bottom: 30px;" v-if="this.notification.text != ''" 
        :feedbackText="this.notification.text" 
        :feedbackLevel="this.notification.level"/> 
      <div v-if="isPageLoading">
        <LoaderView></LoaderView>
      </div>
        <div v-else-if="accounts.resultCount > 0" class="table-responsive mg-t-40">
          <p><b>Total Accounts: </b> [{{ accounts.resultCount  }}]</p>
          <table class="table table-invoice bd-b">
            <thead style="border-bottom: 2px solid #ccc;">
              <tr>
                <th class="tx-left"><b>Name</b></th>
                <th class="tx-right"><b>Status</b></th>
                <th class="tx-right"><b>Date Created</b></th>
                <th class="tx-right"></th>
              </tr>
            </thead>
            <tbody>
              <tr v-bind:key="account.Identifier" v-for="account in accounts.results">
                <td class="tx-left">{{ account.CemeteryName }}</td>
                <td class="tx-right">{{ account.Status }}</td>
                <td class="tx-right">{{ new Date(account.DateCreated).toLocaleDateString() }}</td>
                <td class="tx-right">
                  <a href="#" v-on:click="accountClick(account.Identifier)">view account</a>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</div>

</template>

<script>
import { accountAPI } from "@/api/account";
import RegisterAccountForm from "@/components/Forms/RegisterAccountForm.vue";
import FeedbackNotification from "@/components/FeedbackNotification.vue";
import LoaderView from "@/components/LoaderView.vue";
import { useStatus } from "@/api/composables/status";
import { useErrorLogger } from "@/api/composables/errorLogging";

const pageStatus = useStatus();
const log = useErrorLogger();

export default{
  components: { FeedbackNotification, RegisterAccountForm, LoaderView },
  data() {
    return {
      showAddAccountForm : false,
      accounts : {
        results : [],
        resultCount: 0,
        pageSize: 0,
        pageNumber: 1
      },
      isPageLoading : true,      
      notification : {
        text : "",
        level : "",
      },
    };
  },
 created(){
    this.handleSearchAccounts()
 },
  mounted() {
    document.title = 'Accounts - CliffeX.com'
  },
  methods: {
    async handleSearchAccounts() {
      this.isPageLoading = true;
      const result = await log.catchPromise(() =>
        pageStatus.observe(async () => {
          const result = await accountAPI.search('');
          this.accounts.results = result.Results;
          this.accounts.resultCount = result.ResultCount
          this.accounts.pageSize = result.PageSize
          this.accounts.pageNumber = result.PageNumber
        })
      );
      this.isPageLoading = false;
      if (!result.success) {
        this.notification = { text : result.data, level : "error" };          
      }
    },
    async accountClick(accountIdentifier) {
      this.$router.push('/accounts/' + accountIdentifier);
    },
  },
};
</script>