import { ContactUsRequest } from "@/domain/site/contactUsRequest";
import { ContactUsRequestsResult } from "@/domain/site/contactUsRequestsResult";
import { httpClient } from "./http-client";
const basePath = "/site";

export const siteAPI = {
  getContactRequests: async () => 
  {
    const url = basePath + "/get-contact-requests"
    const response = await httpClient.get(url);
    return response.data as ContactUsRequestsResult;
  }
};