import Vuex from "vuex"

const state = {
    cliffexUserStore: null
};

const store = new Vuex.Store({
    state,
    getters:{
        cliffexUserStore: (state) => {
            //console.log(state.cliffexUserStore)
            return state.cliffexUserStore;
        }
    },
    actions:{
        cliffexUserStore(context, cliffexUserStore){
            context.commit('siteStore', cliffexUserStore)
        }
    },
    mutations:{
        cliffexUserStore(state, cliffexUserStore) {
            state.cliffexUserStore = cliffexUserStore;
        }
    }
})

export default store