<template>
  <header class="navbar navbar-header navbar-header-fixed">
      <a href="" id="mainMenuOpen" class="burger-menu"><i data-feather="menu"></i></a>
      <div class="navbar-brand">
        <a href="/" class="df-logo">Cliff<span>X</span></a>
      </div>
      <div id="navbarMenu" class="navbar-menu-wrapper">
        <div class="navbar-menu-header">
          <a href="/" class="df-logo">Cliff<span>X</span></a>
          <a id="mainMenuClose" href=""><i data-feather="x"></i></a>
        </div>
        <ul class="nav navbar-menu">
          <li class="nav-label pd-l-20 pd-lg-l-25 d-lg-none">Navigation</li>
          <li class="nav-item active">
            <router-link to="/" class="nav-link"><i data-feather="pie-chart"></i> Dashboard</router-link>
          </li>
          <li class="nav-item">
            <router-link to="/accounts/" class="nav-link"><i data-feather="package"></i> Accounts</router-link>
          </li>
          <li class="nav-item">
            <router-link to="/orders/" class="nav-link"><i data-feather="calendar"></i> Orders</router-link>
          </li>
          <!-- <li class="nav-item">
            <router-link to="/lead-finder/" class="nav-link"><i data-feather="calendar"></i> Lead Finder</router-link>
          </li> -->
          <!-- <li class="nav-item with-sub">
            <router-link to="/leads/" class="nav-link"><i data-feather="package"></i> Leads</router-link>
            <ul class="navbar-menu-sub">
              <li class="nav-sub-item">
                <router-link to="/lead-finder/"><i class="fa-solid fa-circle"></i> Lead Finder</router-link>
              </li>
            </ul>
          </li> -->
          <li class="nav-item">
            <router-link to="/contact-requests/" class="nav-link"><i data-feather="package"></i> Website Requests</router-link>
          </li>
          <li class="nav-item" style="">
            <a data-test-key="nav-company-logout" class="nav-link" v-on:click="handleLogout">Logout</a>
          </li>
        </ul>
      </div>
    </header>
</template>

<script>

import { authorisationAPI } from "@/api/authorisation";
import { useStatus } from "@/api/composables/status";
import { useErrorLogger } from "@/api/composables/errorLogging";
import { mapGetters } from "vuex"; 
import router from "@/router";
import store from "@/store/vuex";

const pageStatus = useStatus();
const log = useErrorLogger();

export default {
  name: "NavigationMenu",
 computed :{
    ...mapGetters(['cliffexUserStore'])
 },
    methods: {
    async handleLogout() {
      // remove from local storage
      localStorage.removeItem('cliffex-auth-token');
      // remove from store
      store.dispatch('cliffexUserStore', null)
      // destroy token
      const result = await log.catchPromise(() =>
        pageStatus.observe(async () => {
          const result = await authorisationAPI.logout();
          if(result)
          {
            this.$router.go()
          }
        })
      );
    },
  }
}
</script>

<style lang="scss">
/* styling side menu */
.sideMenu {
  position: absolute;
  width: 400px;
  overflow: auto;
  box-shadow: 1px 0 0 rgb(0 0 0 / 10%);
  height: calc(100vh);
  padding: 40px 30px;
}
.sideMenu::-webkit-scrollbar {
  width: 10px;
}
.sideMenu::-webkit-scrollbar-thumb {
  background-color: #ccc;
}
.sideMenu::-webkit-scrollbar-track {
  background-color: #eee;
}
.sideMenu > ul {
  list-style: none;
  padding: 0;
  display: flex;
  flex-direction: column;
}
.sideMenu > ul > li {
  width: 100%;
}
.sideMenu > ul > li a {
  outline: none;
  border: none;
  width: 100%;
  cursor: pointer;
  text-align: left;
  display: flex;
  font-weight: bold;
  align-items: center;
  gap: 0 15px;
  margin-bottom: 5px;
  font-size: 20px;
  padding: 5px 0;
  background-color: transparent;
}
.sideMenu > ul > li a i {
  font-size: 10px;
}
.sideMenu ul > ul {
  transition: 200ms ease;
  list-style: none;
  margin-left: 3px;
  overflow: hidden;
  padding: 0;
  margin-bottom: 10px;
}
.sideMenu ul > ul > li {
  border-left: 2px solid #ccc;
  width: 100%;
  transition: 200ms ease;
}
.sideMenu ul > ul > li a {
  text-decoration: none;
  transition: 100ms ease;
  display: inline-block;
  width: 100%;
  padding: 10px 20px;
  color: #777;
  font-size: 17px;
  text-transform: capitalize;
}
.sideMenu ul > ul > li:hover {
  border-left: 2px solid dodgerblue;
}
.sideMenu ul > ul > li:hover > a {
  color: #000;
}
button.active {
  color: dodgerblue;
}
</style>
