<template>
    <div>
      <div style="padding: 20px;">
        <h4 style="float: left;">Orders [{{ orders.resultCount  }}]</h4>
        <div style="float: right;">
          <button v-if="!this.showForm" v-on:click="this.showForm = true" class="btn btn-sm pd-x-15 btn-white">
            <i data-feather="mail" class="wd-10 mg-r-5"></i> Create order
          </button>
        </div>
      </div>
      <div style="padding-top: 20px;">
        <AddOrderForm 
          v-if="this.showForm" 
          @formsubmitted="getByAccountIdentifer"
          :accountIdentifier=accountIdentifier 
            />
        <button v-if="this.showForm" v-on:click="this.showForm = false">cancel</button>
      </div>
      <div style="padding-top: 25px;">
        <FeedbackNotification style="margin-bottom: 30px;" v-if="this.notification.text != ''" 
          :feedbackText="this.notification.text" 
          :feedbackLevel="this.notification.level"/> 
        <div v-if="isPageLoading">
          <LoaderView></LoaderView>
        </div>
        <div v-else-if="orders.resultCount > 0">
          <table class="table table-invoice bd-b">
              <thead style="border-bottom: 2px solid #ccc;">
                <tr>
                  <th class="tx-left"><b>Status</b></th>
                  <th class="tx-right"><b>Date Created</b></th>
                  <th class="tx-right"><b></b></th>
                </tr>
              </thead>
              <tbody>
                <tr v-bind:key="order.Identifier" v-for="order in orders.results">
                  <td class="tx-left">{{ order.Status }}</td>
                  <td class="tx-right">{{ new Date(order.DateCreated).toLocaleString() }}</td>
                  <td class="tx-right">
                    <a href="#" v-on:click="orderClick(order.Identifier)">view order</a>
                  </td>
                </tr>
              </tbody>
            </table>
        </div>
        <div v-else>
          <FeedbackNotification style="margin-bottom: 30px;" 
            feedbackText="No orders found" 
            feedbackLevel="notification"/> 
      </div>
      </div>
    </div>
  </template>
  
  <script>
  import { orderAPI } from "@/api/order";
  import AddOrderForm from "@/components/Forms/AddOrderForm.vue";
  import FeedbackNotification from "@/components/FeedbackNotification.vue";
  import LoaderView from "@/components/LoaderView.vue";
  import { useStatus } from "@/api/composables/status";
  import { useErrorLogger } from "@/api/composables/errorLogging";
  
  const pageStatus = useStatus();
  const log = useErrorLogger();
  
  export default {
    props: ["accountIdentifier"],
    components: { FeedbackNotification, AddOrderForm, LoaderView },
    data() {
      return {
        showForm : false,
        orders : {
          results : [],
          resultCount: null,
          pageSize: 0,
          pageNumber: 1
        },           isPageLoading : true,      
        notification : {
          text : "",
          level : "",
        },
      };
    },
   created(){
      this.getByAccountIdentifer()
   },
    methods: {
      async getByAccountIdentifer() {
        this.showForm = false;
        const result = await log.catchPromise(() =>
          pageStatus.observe(async () => {
            const result = await orderAPI.search(this.accountIdentifier);
              this.orders.results = result.Results;
              this.orders.resultCount = result.ResultCount
              this.orders.pageSize = result.PageSize
              this.orders.pageNumber = result.PageNumber          
            })
        );
        this.isPageLoading = false;
        if (!result.success) {
          this.notification = { text : result.data, level : "error" };          
        }
      },
      async orderClick(orderIdentifier) {
        this.$router.push('/orders/' + orderIdentifier);
      },
    },
  };
  </script>