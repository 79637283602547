import { Lead } from "@/domain/lead/lead";
import { LeadsResult } from "@/domain/lead/leadsResult";
import { httpClient } from "./http-client";
const basePath = "/lead";

export const leadAPI = {
  getByIdentifier: async (identifier: string) => 
  {
    const url = basePath + "/getbyidentifier?identifier=" + identifier;
    const response = await httpClient.get(url);
    return response.data as Lead;
  },
  search: async () => 
  {
    const url = basePath + "/search";
    const response = await httpClient.get(url);
    return response.data as LeadsResult;
  },
  add: async (name: string, cemeteryName: string, emailAddress: string, telephoneNumber: string) => 
  {
    const url = basePath + "/add";
    return await httpClient.post(url, {
        name: name,
        cemeteryName: cemeteryName,
        emailAddress: emailAddress,
        telephoneNumber: telephoneNumber,
    });
  },
  setLeadStatus: async (identifier: string, status: string) => 
  {
    const url = basePath + "/set-status";
    return await httpClient.post(url, {
      identifier: identifier,
      leadStatus: status
  });
  },
};