<template>
<div class="content content-fixed bd-b">
  <div class="container pd-x-0 pd-lg-x-10 pd-xl-x-0\">
    <div class="d-sm-flex align-items-center justify-content-between">
    <div>
      <nav aria-label="breadcrumb">
        <ol class="breadcrumb breadcrumb-style1 mg-b-10">
          <li class="breadcrumb-item"><a href="#">Dashboard</a></li>
          <li class="breadcrumb-item" aria-current="page">
          <a v-on:click="handleAccountClick">Account</a>
        </li>
        <li class="breadcrumb-item active" aria-current="page">Contact</li>        
        </ol>
      </nav>
      <h4 class="mg-b-0 tx-spacing--1">Contact [{{ this.contact?.Name }}]</h4>
    </div>
    <div class="col-sm-6 tx-right d-none d-md-block" style="min-width: 120px;">
      <label class="tx-sans tx-uppercase tx-10 tx-medium tx-spacing-1 tx-color-03"> Contact Status</label>
        <h1 class="tx-normal tx-color-04 mg-b-10 tx-spacing--2">
          {{ this.contact?.Status }}
        </h1>
    </div>
    </div>
  </div>
</div>

<div class="content tx-13">
  <div class="container pd-x-0 pd-lg-x-10 pd-xl-x-0">
    <div class="row row-xs">
    <div style="padding-top: 5px;">
      <FeedbackNotification style="margin-bottom: 30px;" v-if="this.notification.text != ''" 
        :feedbackText="this.notification.text" 
        :feedbackLevel="this.notification.level"/> 
      <div v-if="isPageLoading">
        <LoaderView></LoaderView>
      </div>
    <div v-else-if="contact != null">  
        <div>
          <ul class="list-unstyled lh-7">
            <li class="d-flex justify-content-between" style="max-width:400px;">
              <span>Identifier</span><span>{{ contactIdentifier }}</span>
            </li>
            <li class="d-flex justify-content-between" style="max-width:400px;">
              <span>Status</span><span>
                <SetAccountContactStatusForm 
                  v-if="this.contact != null"
                  @formsubmitted="handleContactStatusUpdated" 
                  :contactIdentifier="contactIdentifier"  
                  :contactStatus="this.contact.Status" 
                  />          
              </span>
            </li>
            <li class="d-flex justify-content-between" style="max-width:400px;">
              <span>Name</span><span>{{ contact.Name }}</span>
            </li>
            <li class="d-flex justify-content-between" style="max-width:400px;">
              <span>Email Address</span><span>{{ contact.EmailAddress }}</span>
            </li>
            <li class="d-flex justify-content-between" style="max-width:400px;">
              <span>Telephone Number</span><span>{{ contact.TelephoneNumber }}</span>
            </li>
            <li class="d-flex justify-content-between" style="max-width:400px;">
              <span>Date Updated</span><span>{{ contact.DateUpdated != null ? new Date(contact.DateUpdated).toLocaleString() : 'n/a' }}</span>
            </li>
            <li class="d-flex justify-content-between" style="max-width:400px;">
              <span>Date Created</span><span>{{ new Date(contact.DateCreated).toLocaleString() }}</span>
            </li>
          </ul>
        </div>
        <div style="padding-top: 25px;">
          <NoteContainer 
            @formsubmitted="handleGetContact"
            :noteIdentifier="this.contactIdentifier" 
            noteType="Contact"  
          />      
        </div>
      </div>
    </div>
  </div>
</div></div>
</template>
  
  <script>
import { contactAPI } from "@/api/contact";
import FeedbackNotification from "@/components/FeedbackNotification.vue";
import SetAccountContactStatusForm from "@/components/Forms/SetAccountContactStatusForm.vue";
import NoteContainer from "@/components/NoteContainer.vue";
import LoaderView from "@/components/LoaderView.vue";
import router from "@/router";

import { useStatus } from "@/api/composables/status";
import { useErrorLogger } from "@/api/composables/errorLogging";

const pageStatus = useStatus();
const log = useErrorLogger();

  export default {
    components :{
      FeedbackNotification, SetAccountContactStatusForm, NoteContainer, LoaderView
    },  
    data() {
      return {
        isPageLoading : true,
        contactIdentifier : this.$route.params.contactIdentifier,
        contact : null,
        notification : {
          text : "",
          level : "",
        },
      };
    },
    created(){
        this.handleGetContact()
    },
    mounted() {
      document.title = 'Contact - CliffeX.com'
    },
    methods: {
      handleContactStatusUpdated: function(e)
      {
        this.contact.Status = e;
      },
      handleAccountClick: function(e)
      {
        router.push({ name: 'AccountView', params: { accountIdentifier: this.contact?.AccountIdentifier } });
      },
      async handleGetContact() {
        this.isPageLoading = true;
        const result = await log.catchPromise(() =>
          pageStatus.observe(async () => {
            const result = await contactAPI.getByIdentifier(this.contactIdentifier);
            this.contact = result;
          })
        );
        this.isPageLoading = false;
        if (!result.success) {
          this.notification = { text : result.data, level : "error" };          
        }
      },
  },
};
  </script>