<template>
    <h4>Login</h4>
    <div v-if="pageLoading">
      <LoaderView/>
    </div>
    <div v-else>
      <div v-if="!isFormSubmitted">
        <p>
          <input class="formInput" v-model="state.loginForm.userName" placeholder="user" /><br>
          <ValidationMessage v-if="v$.loginForm.userName.$error" :validationMessage ="v$.loginForm.userName.$errors[0].$message"/>
        </p>
        <p>
          <input class="formInput" v-model="state.loginForm.password" placeholder="password" type="password" /><br>
          <ValidationMessage v-if="v$.loginForm.password.$error" :validationMessage ="v$.loginForm.password.$errors[0].$message"/>
        </p>
        <p>
          <button class="btn btn-brand-02 btn-block" v-on:click="handleAddAccountContact">Login</button>
        </p>
      </div>
      <FeedbackNotification v-if="this.notification.text != ''" 
        :feedbackText="this.notification.text" 
        :feedbackLevel="this.notification.level"/>  
    </div>
  </template>
  
  <script>
  
  import ValidationMessage from "@/components/ValidationMessage.vue";
  import LoaderView from "@/components/LoaderView.vue";
  import FeedbackNotification from "@/components/FeedbackNotification.vue";
  import { authorisationAPI } from "@/api/authorisation";
  import { useStatus } from "@/api/composables/status";
  import { useErrorLogger } from "@/api/composables/errorLogging";
  import { httpClient } from "@/api/http-client";

  import { reactive, computed } from 'vue' 
  import useVuelidate from '@vuelidate/core'
  import { required } from '@vuelidate/validators'
  
  const pageStatus = useStatus();
  const log = useErrorLogger();
  
  export default {
    components : {
      FeedbackNotification, ValidationMessage, LoaderView
    }, 
    setup () {
      const state = reactive({
        loginForm: {
            userName: "",
            password: "",
        }
      });
      const rules = computed(() => {
        return {
          loginForm: {
            userName: { required },
            password: { required },
          }
        }
      })
      const v$ = useVuelidate(rules, state)
      return { state, v$ }
    },
    data() {
      return {
        pageLoading : false,
        notification : {
          text : "",
          level : "",
        },
        isFormSubmitted: false
      };
    },
    methods: {
      async handleAddAccountContact() {
        // validate the form
        this.v$.$validate();
        if(!this.v$.$error)
        {
          this.pageLoading = true;
          const result = await log.catchPromise(() =>
           pageStatus.observe(async () => {
             const result = await authorisationAPI.login(
                this.state.loginForm.userName,
                this.state.loginForm.password);

                if (result.data.Token == null)
                {
                  this.notification = { text : "Not Authenticated", level : "success" };  
                }
                else
                {
                  // close the form
                  this.isFormSubmitted = true;

                  // set feedback
                  this.notification = { text : "Authenticated", level : "success" };  

                  // set local storage token
                  localStorage.setItem('cliffex-auth-token', result.data.Token);

                  // store the user with vuex
                  const cliffexUser = {
                      Username : result.data.Token
                  } 
                  // store the user
                  //this.$store.dispatch('cliffexUserStore', cliffexUser);

                  // set axios headers
                  httpClient.defaults.headers.common['X-cliffex-auth'] = '' + result.data.Token

                  // navigate to new page
                  this.$router.go(this.$router.currentRoute)

                  // notify the parent view  
                  this.$emit('formsubmitted');
                }
              })
          );
          this.pageLoading = false;

          if(!result.success){
            this.notification = { text : result.data, level : "error" };      
          }
        }
      }
    },
  };
  </script>