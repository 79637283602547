<template>
    <h3>Set order as payment complete</h3>
    <div v-if="pageLoading">
      <img class="_imgLoader" src="@/assets/images/loading.gif"/>
    </div>
    <div v-else>
      <div v-if="!isFormSubmitted">
        <p>
          <input class="formInput" v-model="state.registerForm.bankReference" placeholder="bank reference" /><br>
          <ValidationMessage v-if="v$.registerForm.bankReference.$error" :validationMessage ="v$.registerForm.bankReference.$errors[0].$message"/>
        </p>
        <p>
          <button class="btn btn-brand-02 btn-block" v-on:click="handleSetPaymentComplete">Submit</button>
        </p>
      </div>
      <FeedbackNotification v-if="this.notification.text != ''" 
        :feedbackText="this.notification.text" 
        :feedbackLevel="this.notification.level"/>  
    </div>
  </template>
  
  <script>
  
  import ValidationMessage from "@/components/ValidationMessage.vue";
  import FeedbackNotification from "@/components/FeedbackNotification.vue";
  import { orderAPI } from "@/api/order";
  import { useStatus } from "@/api/composables/status";
  import { useErrorLogger } from "@/api/composables/errorLogging";
  
  import { reactive, computed } from 'vue' 
  import useVuelidate from '@vuelidate/core'
  import { required, minLength } from '@vuelidate/validators'
  
  const pageStatus = useStatus();
  const log = useErrorLogger();
  
  export default {
    components : {
      FeedbackNotification, ValidationMessage
    }, 
    setup () {
      const state = reactive({
        registerForm: {
          bankReference: "",
        }
      });
      const rules = computed(() => {
        return {
          registerForm: {
            bankReference:  { required, minLength: minLength(3) },
          }
        }
      })
      const v$ = useVuelidate(rules, state)
      return { state, v$ }
    },
    data() {
      return {
        pageLoading : false,
        notification : {
          text : "",
          level : "",
        },
        isFormSubmitted: false
      };
    },
    methods: {
      async handleSetPaymentComplete() {
        // validate the form
        this.v$.$validate();
        if(!this.v$.$error)
        {
          this.pageLoading = true;
          const result = await log.catchPromise(() =>
           pageStatus.observe(async () => {
             const result = await orderAPI.setPaymentComplete(this.state.registerForm.companyName, 
                this.state.registerForm.emailAddress, this.state.registerForm.password.password);
                // success       
                 this.isFormSubmitted = true;
                 this.notification = { text : "Account Registered.", level : "success" };  
                // notify the parent view  
                 this.$emit('formsubmitted');
              })
          );
          this.pageLoading = false;
          if (!result.success) {
            this.notification = { text : result.data, level : "error" };      
          }
        }
      }
    },
  };
  </script>