import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";
import DashboardView from "../views/DashboardView.vue";
import AccountsView from "../views/AccountsView.vue";
import AccountView from "../views/AccountView.vue";
import OrdersView from "../views/OrdersView.vue";
import OrderView from "../views/OrderView.vue";
import ContactRequestView from "../views/ContactRequestView.vue";
import LeadFinderView from "../views/LeadFinderView.vue";
import LeadsView from "../views/LeadsView.vue";
import LeadView from "../views/LeadView.vue";
import AccountContactView from "../views/AccountContactView.vue";
import NotFoundView from "../views/NotFoundView.vue";

const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    name: "DashboardView",
    component: DashboardView,
  },  
  {
    path: "/accounts/",
    name: "AccountsView",
    component: AccountsView,
  }, 
  {
    path: "/contacts/:contactIdentifier/",
    name: "AccountContactView",
    component: AccountContactView,
  },  
  {
    path: "/accounts/:accountIdentifier/",
    name: "AccountView",
    component: AccountView,
  },  
  {
    path: "/orders/",
    name: "OrdersView",
    component: OrdersView,
  },  
  {
    path: "/orders/:orderIdentifier/",
    name: "OrderView",
    component: OrderView,
  },  
  {
    path: "/contact-requests/",
    name: "ContactRequestView",
    component: ContactRequestView,
  },  
  {
    path: "/lead-finder/",
    name: "LeadFinderView",
    component: LeadFinderView,
  },  
  {
    path: "/leads/",
    name: "LeadsView",
    component: LeadsView,
  },  
  {
    path: "/leads/:leadIdentifier/",
    name: "LeadView",
    component: LeadView,
  },  
  {
    path: "/:pathMatch(.*)*",
    name: "notFound",
    component: NotFoundView,
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
